import React from "react";
import { Link } from "react-router-dom";
import GoogleLogo from "../assets/google-logo.png";
import Logo from "../assets/logo.png";
import Tinder from "../assets/landing/brands/tinder.png";
import Bumble from "../assets/landing/brands/bumble.png";
import Baddo from "../assets/landing/brands/baddo.png";
import Hinge from "../assets/landing/brands/hinge.png";

const stats = [
  { value: "10X", label: "More Matches" },
  { value: "98%", label: "Success Rate" },
  { value: "24h", label: "Results" },
];

const brands = [
  {
    title: "Tinder",
    img: Tinder,
  },
  {
    title: "Bumble",
    img: Bumble,
  },
  {
    title: "Baddo",
    img: Baddo,
  },
  {
    title: "Hinge",
    img: Hinge,
  },
];

export default function Auth() {
  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="bg-white">
        <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
          <Link to="/" className="flex items-center space-x-1">
            <img src={Logo} className="h-6" alt="Logo" />
            <span className="text-2xl font-semibold">RizzShot</span>
          </Link>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center w-full min-h-screen px-4">
        <div className="max-w-[900px] w-full mx-auto text-center mb-8 px-4">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-700 mb-3">
            Get Started with RizzShot
          </h1>
          <p className="text-sm sm:text-base text-gray-600 mb-2">
            Sign in to access your account and start getting more matches today
          </p>
          <p className="text-sm text-orange-600 mb-6">
            Trained on 100,000+ successful profiles
          </p>

          {/* Stats Section */}
          <div className="flex justify-center gap-8 sm:gap-12 mb-10 text-xs text-gray-600">
            {stats.map(({ value, label }, index) => (
              <div key={index} className="flex flex-col items-center gap-1">
                <span className="font-semibold text-orange-600 text-lg">
                  {value}
                </span>
                <span>{label}</span>
              </div>
            ))}
          </div>

          {/* Brands Section */}
          <div className="mb-10">
            <p className="text-sm text-gray-600 mb-4">Successfully verified and tested on</p>
            <div className="flex flex-wrap justify-center gap-6">
              {brands.map((brand) => (
                <div key={brand.title} className="flex items-center">
                  <img src={brand.img} alt={brand.title} className="h-8 w-auto opacity-80" />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Sign In Section */}
        <div className="max-w-[400px] w-full mx-auto px-4">
          <div className="border-gray-300 border rounded-3xl p-6 sm:p-8">
            <button
              onClick={handleGoogleLogin}
              className="w-full flex items-center justify-center gap-3 bg-white border border-gray-300 text-gray-700 rounded-3xl px-6 py-3 hover:bg-gray-50 transition"
            >
              <img src={GoogleLogo} alt="Google" className="w-5 h-5" />
              <span className="font-normal text-base">Sign in with Google</span>
            </button>
            <p className="mt-6 text-center text-sm text-gray-600">
              By signing in, you agree to our{" "}
              <Link
                to="/terms-of-service"
                className="text-orange-600 hover:underline"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy-policy"
                className="text-orange-600 hover:underline"
              >
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
