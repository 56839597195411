// ImageGeneration.js
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ImageIcon from "@mui/icons-material/Image";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { saveAs } from "file-saver";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modelSelectors } from "../app/slices/model";
import { setRemainingToken } from "../app/slices/user";
import { getHistory } from "../app/slices/history";
import UpgradePrompt from "./UpgradePrompt";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
    boxShadow: theme.shadows[4],
  },
}));

const LargeCardMedia = styled(CardMedia)({
  paddingTop: "100%",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: "10px",
  borderRadius: theme.shape.borderRadius * 2,
}));

const ImageGeneration = () => {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation();
  const _modelSelectors = useSelector(modelSelectors);
  const models = _modelSelectors.data.models;
  const [selectedModel, setSelectedModel] = useState(
    locationState?.selectedModel || ""
  );
  const [error, setError] = useState("");
  const [generationPrompt, setGenerationPrompt] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [similarGenerating, setSimilarGenerating] = useState(false);
  const [scale] = useState(1);
  const [seed, setSeed] = useState("");
  const [numImages, setNumImages] = useState(1);
  const [activeTab, setActiveTab] = useState("pre");
  const { data } = useSelector((state) => state.user);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { histories } = useSelector(getHistory(selectedModel));

  const [_generatedImg, _setGeneratedImg] = useState([]);

  const handleBuyCredits = () => {
    // Implement logic to redirect to credit purchase page or open a modal
    console.log("Redirecting to buy credits");
  };

  useEffect(() => {
    if (activeTab === "pre" && Array.isArray(models)) {
      const model = models.find((model) => model?.name === selectedModel);
      if (model && Array.isArray(model?.preGenerated)) {
        const _preGenerated = model.preGenerated.map((preImg) => ({
          url: preImg?.url,
          id: preImg?._id,
        }));
        _setGeneratedImg([..._preGenerated]);
      } else {
        _setGeneratedImg([]);
      }
    } else if (activeTab === "new" && Array.isArray(generatedImages)) {
      const _generated = generatedImages.map((img) => ({
        url: img?.url,
      }));
      _setGeneratedImg([..._generated]);
    } else if (activeTab === "history" && Array.isArray(histories)) {
      const _histories = histories.map((img) => ({
        url: img?.imgUrl,
      }));
      _setGeneratedImg([..._histories]);
    } else {
      _setGeneratedImg([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, models, selectedModel, generatedImages]);

  const generateImage = async () => {
    const token = localStorage.getItem("token");

    if (!selectedModel) {
      setError("No valid trained model selected. Please select a model first.");
      return;
    }

    if (numImages > 4) {
      setError("You cannot generate more than 4 images at once.");
      return;
    }

    setGenerating(true);
    setError("");

    try {
      const selectedModelData = models.find(
        (model) => model.name === selectedModel
      );
      if (
        !selectedModelData ||
        (!selectedModelData.s3Url && !selectedModelData.falUrl)
      ) {
        throw new Error("Selected model does not have a valid URL");
      }
      const requestData = {
        prompt: generationPrompt,
        modelName: selectedModel,
        scale: scale,
        seed: seed ? parseInt(seed) : undefined,
        num_images: numImages,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/engine/generate-image`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.generatedImages &&
        response.data.generatedImages.length > 0
      ) {
        dispatch(
          setRemainingToken({
            numberOfToken: numImages,
            creaditKey: "credits.imageGeneration",
          })
        );
        setGeneratedImages(response.data.generatedImages);
      } else {
        throw new Error("No image data in the response");
      }

      if (response.data.remainingCredits !== undefined) {
        // setCredits(response.data.remainingCredits);
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Error generating image. Please try again."
      );
    } finally {
      setGenerating(false);
    }
  };

  const handleDownload = (imageUrl, fileName) => {
    saveAs(imageUrl, fileName);
  };

  const handleTabChange = (_, tabId) => {
    setActiveTab(tabId);
  };

  const handlePreviewOpen = (image) => {
    setSelectedImage(image);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setSelectedImage(null);
  };

  const handleTryFromFree = async (id) => {
    try {
      if (id) {
        const token = localStorage.getItem("token");
        setSimilarGenerating(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/engine/try-from-pre`,
          { promptId: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSimilarGenerating(false);
        const generatedImages = response.data.generatedImages;
        if (generatedImages && generatedImages.length > 0) {
          dispatch(
            setRemainingToken({
              numberOfToken: 1,
              creaditKey: "credits.imageGeneration",
            })
          );
          const urls = generatedImages.map((img) => ({ url: img.url }));
          _setGeneratedImg([..._generatedImg, ...urls]);
        }
      }
    } catch (error) {}
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 4 }}>
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          Create Stunning Dating Photos in Seconds
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary">
          Transform your dating profile with AI Photographer that get more
          matches
        </Typography>
      </Box>

      <Box mb={4}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mb: 4,
            backgroundColor: "primary.light",
            color: "primary.contrastText",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Your Remaining Credits: {data.credits?.imageGeneration ?? 0} Photos
          </Typography>
        </Paper>
        {data.subscriptionStatus === "active" &&
          data.credits?.imageGeneration <= 0 && (
            <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6} container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleBuyCredits}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Buy More Credits
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
      </Box>

      {data.subscriptionStatus !== "active" && (
        <Box mb={4}>
          <UpgradePrompt userData={data} />
        </Box>
      )}

      <StyledPaper elevation={3}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{ mb: 3 }}
        >
          <Tab label="Ready-to-Use Photos" value="pre" />
          <Tab label="Create Custom Photos" value="new" />
          <Tab label="History" value="history" />
        </Tabs>

        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h5" gutterBottom color="primary">
                Settings
              </Typography>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel id="model-select-label">
                  Choose Your Model
                </InputLabel>
                <Select
                  labelId="model-select-label"
                  value={selectedModel}
                  label="Choose Your Model"
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  {models.map((model) => (
                    <MenuItem key={model.name} value={model.name}>
                      {model.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {activeTab === "new" ? (
                <>
                  <TextField
                    fullWidth
                    label="Describe Your Perfect Photo"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={generationPrompt}
                    onChange={(e) => setGenerationPrompt(e.target.value)}
                    sx={{ mb: 3 }}
                    placeholder="Example:  Generate an image of a man enjoying quality time with his dog in a park during golden hour. Evoke feelings of warmth and connection, emphasizing their playful interaction and the golden light that creates a cozy atmosphere..."
                  />
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Seed (Optional)"
                        variant="outlined"
                        value={seed}
                        onChange={(e) => setSeed(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    label="Number of Variations"
                    type="number"
                    variant="outlined"
                    value={numImages}
                    onChange={(e) => {
                      const value = Math.min(Number(e.target.value), 4); // Limit to 4
                      setNumImages(value);
                    }}
                    sx={{ mb: 3 }}
                    helperText="Create up to 4 variations at once" // Helper text
                    inputProps={{ min: 1, max: 4 }} // Set min and max values
                  />

                  <Button
                    variant="contained"
                    onClick={generateImage}
                    disabled={
                      generating ||
                      !selectedModel ||
                      activeTab === "pre" ||
                      !generationPrompt
                    }
                    fullWidth
                    startIcon={<ImageIcon />}
                    size="large"
                  >
                    {generating
                      ? "Creating Your Photos..."
                      : "Generate My Photos Now"}
                  </Button>
                </>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Box marginTop={8}>
          <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            color="primary"
          >
            {Array.isArray(_generatedImg) && _generatedImg.length ? (
              <>
                {activeTab === "pre" ? "Trending Photo Styles For" : ""} Dating
                Profile Images
              </>
            ) : null}
          </Typography>

          {generating ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2}>
              {_generatedImg.map((image, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <StyledCard>
                    <LargeCardMedia
                      image={image.url}
                      title={`Pre-generated ${index + 1}`}
                    />
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        fullWidth
                        startIcon={<CloudDownloadIcon />}
                        onClick={() =>
                          handleDownload(
                            image.url,
                            `pre-generated-image-${index + 1}.jpg`
                          )
                        }
                      >
                        Download
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        fullWidth
                        startIcon={<VisibilityIcon />}
                        onClick={() => handlePreviewOpen(image.url)}
                      >
                        Preview
                      </Button>
                    </CardActions>
                    {image?.id && (
                      <CardActions>
                        <Button
                          disabled={similarGenerating}
                          size="small"
                          color="primary"
                          fullWidth
                          startIcon={<TextSnippetIcon />}
                          onClick={() => handleTryFromFree(image.id)}
                        >
                          Create Similar Style
                        </Button>
                      </CardActions>
                    )}
                  </StyledCard>
                </Grid>
              ))}
              {similarGenerating ? (
                <Grid item xs={6} sm={4} md={3}>
                  <StyledCard className="flex items-center justify-center">
                    <CircularProgress />
                  </StyledCard>
                </Grid>
              ) : null}
            </Grid>
          )}
        </Box>
      </StyledPaper>

      <Modal open={previewOpen} onClose={handlePreviewClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "70%" },
            maxWidth: "1200px",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            overflow: "hidden", // Prevents image overflow
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "white",
              zIndex: 1,
              bgcolor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.7)",
              },
            }}
            onClick={handlePreviewClose}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <Box
              sx={{
                width: "100%",
                position: "relative",
                paddingTop: "56.25%", // 16:9 aspect ratio
              }}
            >
              <img
                src={selectedImage}
                alt="Preview"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  backgroundColor: "black",
                }}
              />
            </Box>
          )}
        </Box>
      </Modal>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default ImageGeneration;
