import { useState, useCallback, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./layout/GHeader";
import Footer from "./layout/GFooter";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import logo from "../assets/landing/brands/logo512.png";
import EUImg from "../assets/landing/eu.jpg";
import heic2any from "heic2any";

const Container = styled("div")({
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "40px 20px",
});

const ScoreCard = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "24px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  marginBottom: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const PromoCard = styled("div")({
  backgroundColor: "#fff7ed",
  borderRadius: "16px",
  padding: "24px",
  margin: "24px 0",
  border: "1px solid #fed7aa",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "4px",
    height: "100%",
    background: "linear-gradient(to bottom, #ea580c, #fb923c)",
  },
});

const ScoreMetricCard = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "24px",
  boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "translateY(-2px)",
  },
});

const ScoreValue = styled("div")({
  display: "flex",
  alignItems: "baseline",
  gap: "4px",
  "& .number": {
    fontSize: "32px",
    fontWeight: "700",
    background: "linear-gradient(45deg, #ea580c 30%, #fb923c 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "& .max": {
    fontSize: "16px",
    color: "#999",
  },
});

const formatAnalysisResults = (analysis, navigate) => {
  const isSinglePhoto = analysis.analyses.length === 1;

  return (
    <div className="mt-12 max-w-3xl mx-auto">
      {/* Overall Score Card */}
      <ScoreCard>
        <div>
          <h2 className="text-3xl font-semibold mb-2">
            🎯 Match Success Probability
          </h2>
          <div className="text-sm text-gray-600 mb-2">
            Analyzed by AI trained on 100k+ successful profiles
          </div>
          {analysis.overallScore <= 6 ? (
            <div className="text-red-500 text-sm font-medium">
              Warning: Your current photos make it hard to get matches. With
              RizzShot.ai, you can get professional-quality photos that increase
              your match rate by 10x! 🚀
            </div>
          ) : analysis.overallScore <= 8 ? (
            <div className="text-yellow-600 text-sm font-medium">
              You're getting some matches, but you're not reaching your full
              potential. RizzShot.ai can help you get 10x more quality matches!
              ⭐
            </div>
          ) : (
            <div className="text-green-600 text-sm font-medium">
              Great photos! You're in the top 10% of profiles. You should be
              getting quality matches consistently! 🔥
            </div>
          )}
          <div className="flex items-center gap-1">
            {[...Array(5)].map((_, i) => (
              <StarIcon
                key={i}
                sx={{
                  color:
                    i < Math.round(analysis.overallScore / 2)
                      ? "#ea580c"
                      : "#E0E0E0",
                  fontSize: 28,
                }}
              />
            ))}
          </div>
        </div>
        <div className="w-32 flex items-center justify-center">
          <div className="flex items-center justify-center flex-col bg-orange-600 rounded-full w-[120px] h-[120px] border border-orange-600 text-white">
            <div className="text-xl font-semibold">
              {analysis.overallScore}/10
            </div>
            <div className="text-xs text-wrap text-center">
              {analysis.overallScore <= 6
                ? "Need RizzShot.ai!"
                : analysis.overallScore <= 8
                ? "Good, Can Be Better"
                : "Top Profile"}
            </div>
          </div>
        </div>
      </ScoreCard>

      {/* First photo analysis */}
      {analysis.analyses[0] && (
        <div key={0} className="bg-white rounded-xl p-8 shadow-lg mt-8">
          <div className="flex items-center justify-between mb-8">
            <h3 className="text-2xl font-bold text-gray-800">Photo 1</h3>
          </div>

          {/* Enhanced Scores Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {[
              {
                label: "Overall",
                value: analysis.analyses[0].scores.overall || 0,
                icon: "🎯",
              },
              {
                label: "Lighting",
                value: analysis.analyses[0].scores.lighting || 0,
                icon: "💡",
              },
              {
                label: "Pose",
                value: analysis.analyses[0].scores.pose || 0,
                icon: "🎭",
              },
              {
                label: "Background",
                value: analysis.analyses[0].scores.background || 0,
                icon: "🖼️",
              },
            ].map((metric) => (
              <ScoreMetricCard key={metric.label}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#FFF5F8",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "24px",
                  }}
                >
                  {metric.icon}
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "4px",
                    }}
                  >
                    {metric.label}
                  </div>
                  <ScoreValue>
                    <span className="number">{metric.value}</span>
                    <span className="max">/10</span>
                  </ScoreValue>
                </div>
              </ScoreMetricCard>
            ))}
          </div>

          {/* Feedback Text */}
          <div className="text-gray-600 text-sm whitespace-pre-line bg-gray-50 rounded-xl p-6">
            {analysis.analyses[0].feedback.split('\n').map((line, index) => {
              // Skip score lines
              if (line.match(/^(Overall|Lighting|Pose|Background):\s*\d+\/10/) || 
                  line.includes('Image Quality:')) {
                return null;
              }
              
              // Check if line is a section title
              if (line.includes('Emotional Expression:') || 
                  line.includes('Image Type and Context:') || 
                  line.includes('Dating Profile Suitability:')) {
                return (
                  <div key={index} className="text-gray-800 text-lg font-bold mt-6 mb-3">
                    {line}
                  </div>
                );
              }
              
              // Special formatting for Strengths and Quick Fixes sections
              if (line.includes('✨ Strengths:')) {
                return (
                  <div key={index} className="text-gray-800 text-lg font-bold mt-6 mb-3 flex items-center gap-2">
                    <span className="text-2xl">✨</span>
                    <span>Strengths</span>
                  </div>
                );
              }
              
              if (line.includes('🎯 Quick Fixes with RizzShot.ai:')) {
                return (
                  <div key={index} className="text-gray-800 text-lg font-bold mt-6 mb-3 flex items-center gap-2">
                    <span className="text-2xl">🎯</span>
                    <span>Quick Fixes with RizzShot.ai</span>
                  </div>
                );
              }
              
              // Format bullet points
              if (line.trim().startsWith('•')) {
                return (
                  <div key={index} className="flex gap-2 mb-2 ml-2">
                    <span className="text-orange-500">•</span>
                    <span>{line.substring(1).trim()}</span>
                  </div>
                );
              }
              
              // Regular text
              if (line.trim()) {
                return (
                  <div key={index} className="mb-2">
                    {line}
                  </div>
                );
              }
              
              return null;
            })}
          </div>
        </div>
      )}

      {/* Show PromoCard after first photo */}
      <PromoCard style={{ marginTop: "24px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "8px" }}>
              Ready to Transform Your Dating Profile?
            </h3>
            <p style={{ fontSize: "14px", color: "#666", marginBottom: "16px" }}>
              Get professional photos that match your personality with RizzShotAI. Our AI photographer helps you create a profile that stands out and attracts the right matches.
            </p>
            <Button
              style={{
                padding: "8px 16px",
                fontSize: "14px",
              }}
              onClick={() => navigate("/login")}
            >
              Try RizzShotAI Premium →
            </Button>
          </div>
          <div
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: "#fff7ed",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="RizzShotAI"
              style={{ width: "60px", height: "60px" }}
            />
          </div>
        </div>
      </PromoCard>

      {/* Remaining photo analyses for multiple photos */}
      {!isSinglePhoto && analysis.analyses.slice(1).map((item, index) => (
        <div key={index + 1} className="bg-white rounded-xl p-8 shadow-lg mt-8">
          <div className="flex items-center justify-between mb-8">
            <h3 className="text-2xl font-bold text-gray-800">
              Photo {index + 2}
            </h3>
          </div>

          {/* Enhanced Scores Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {[
              { label: "Overall", value: item.scores.overall || 0, icon: "🎯" },
              {
                label: "Lighting",
                value: item.scores.lighting || 0,
                icon: "💡",
              },
              { label: "Pose", value: item.scores.pose || 0, icon: "🎭" },
              {
                label: "Background",
                value: item.scores.background || 0,
                icon: "🖼️",
              },
            ].map((metric) => (
              <ScoreMetricCard key={metric.label}>
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#FFF5F8",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "24px",
                  }}
                >
                  {metric.icon}
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "4px",
                    }}
                  >
                    {metric.label}
                  </div>
                  <ScoreValue>
                    <span className="number">{metric.value}</span>
                    <span className="max">/10</span>
                  </ScoreValue>
                </div>
              </ScoreMetricCard>
            ))}
          </div>

          {/* Feedback Text */}
          <div className="text-gray-600 text-sm whitespace-pre-line bg-gray-50 rounded-xl p-6">
            {item.feedback.split('\n').map((line, index) => {
              // Skip score lines
              if (line.match(/^(Overall|Lighting|Pose|Background):\s*\d+\/10/) || 
                  line.includes('Image Quality:')) {
                return null;
              }
              
              // Check if line is a section title
              if (line.includes('Emotional Expression:') || 
                  line.includes('Image Type and Context:') || 
                  line.includes('Dating Profile Suitability:')) {
                return (
                  <div key={index} className="text-gray-800 text-lg font-bold mt-6 mb-3">
                    {line}
                  </div>
                );
              }
              
              // Special formatting for Strengths and Quick Fixes sections
              if (line.includes('✨ Strengths:')) {
                return (
                  <div key={index} className="text-gray-800 text-lg font-bold mt-6 mb-3 flex items-center gap-2">
                    <span className="text-2xl">✨</span>
                    <span>Strengths</span>
                  </div>
                );
              }
              
              if (line.includes('🎯 Quick Fixes with RizzShot.ai:')) {
                return (
                  <div key={index} className="text-gray-800 text-lg font-bold mt-6 mb-3 flex items-center gap-2">
                    <span className="text-2xl">🎯</span>
                    <span>Quick Fixes with RizzShot.ai</span>
                  </div>
                );
              }
              
              // Format bullet points
              if (line.trim().startsWith('•')) {
                return (
                  <div key={index} className="flex gap-2 mb-2 ml-2">
                    <span className="text-orange-500">•</span>
                    <span>{line.substring(1).trim()}</span>
                  </div>
                );
              }
              
              // Regular text
              if (line.trim()) {
                return (
                  <div key={index} className="mb-2">
                    {line}
                  </div>
                );
              }
              
              return null;
            })}
          </div>
        </div>
      ))}

      {/* Show PromoCard at the end only if there are multiple photos */}
      {!isSinglePhoto && (
        <PromoCard style={{ marginTop: "32px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div style={{ flex: 1 }}>
              <h3
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#ea580c",
                  marginBottom: "8px",
                }}
              >
                Ready to Transform Your Dating Profile?
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  color: "#666",
                  marginBottom: "12px",
                  lineHeight: 1.5,
                }}
              >
                Get professional photos that match your personality with RizzShotAI. Our AI photographer helps you create a profile that stands out and attracts the right matches.
              </p>
              <Button
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                }}
                onClick={() => navigate("/login")}
              >
                Get Started with RizzShotAI →
              </Button>
            </div>
            <div
              style={{
                width: "100px",
                height: "100px",
                backgroundColor: "#fff7ed",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="RizzShotAI"
                style={{ width: "60px", height: "60px" }}
              />
            </div>
          </div>
        </PromoCard>
      )}
    </div>
  );
};

const Title = styled("h1")({
  fontSize: "48px",
  textAlign: "center",
  color: "#333",
  marginBottom: "16px",
  fontWeight: "600",
});

const Subtitle = styled("p")({
  fontSize: "18px",
  textAlign: "center",
  color: "#666",
  marginBottom: "48px",
});

const UploadSection = styled("div")({
  display: "flex",
  gap: "32px",
  marginBottom: "48px",
  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
});

const UploadBox = styled("div")({
  flex: "1",
  border: "2px dashed #fb923c",
  borderRadius: "16px",
  padding: "40px",
  backgroundColor: "#fff7ed",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "300px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#fed7aa",
  },
});

const PreviewSection = styled("div")({
  flex: "1",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
});

const Button = styled("button")({
  backgroundColor: "#ea580c",
  color: "#fff",
  border: "none",
  borderRadius: "24px",
  padding: "12px 32px",
  fontSize: "16px",
  fontWeight: "500",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#c2410c",
  },
  "&:disabled": {
    backgroundColor: "#ccc",
    cursor: "not-allowed",
  },
});

const convertHeicToJpeg = async (file) => {
  // More comprehensive HEIC detection
  const isHeic = file.type === "image/heic" || 
                 file.type === "image/heif" || 
                 file.name.toLowerCase().endsWith('.heic') || 
                 file.name.toLowerCase().endsWith('.heif');

  if (isHeic) {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1,
        multiple: false
      });
      return new File([convertedBlob], file.name.replace(/\.(heic|HEIF)$/i, '.jpg'), {
        type: 'image/jpeg'
      });
    } catch (err) {
      console.error('HEIC conversion error:', err);
      throw new Error(`Failed to convert ${file.name}`);
    }
  }
  return file;
};

const renderError = (errorMessage) => {
  try {
    // Try to parse the error message if it's JSON
    const parsedError = typeof errorMessage === 'string' ? JSON.parse(errorMessage) : errorMessage;

    // Handle group photo error
    if (parsedError.title === "Group Photo Detected") {
      return (
        <div className="bg-orange-50 border border-orange-200 rounded-lg p-6 text-center my-4">
          <div className="text-2xl mb-4">👥</div>
          <h3 className="text-orange-800 font-semibold text-lg mb-3">
            {parsedError.title}
          </h3>
          <p className="text-gray-700 mb-4">{parsedError.message}</p>
          <div className="bg-white rounded-lg p-4 mb-4">
            <h4 className="text-gray-800 font-medium mb-2">Quick Tips:</h4>
            <ul className="text-left text-gray-600 space-y-2">
              {parsedError.tips.map((tip, index) => (
                <li key={index} className="flex items-start">
                  <span className="mr-2">✨</span>
                  <span>{tip}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }

    // Handle AI-generated image error
    if (errorMessage.toLowerCase().includes("ai-generated")) {
      return (
        <div className="bg-orange-50 border border-orange-200 rounded-lg p-6 text-center my-4">
          <div className="text-2xl mb-4">🤖</div>
          <h3 className="text-orange-800 font-semibold text-lg mb-3">
            AI-Generated Image Detected
          </h3>
          <p className="text-gray-700 mb-4">
            Please upload your current photos first so we can analyze them before creating AI-enhanced versions.
          </p>
        </div>
      );
    }

    // Handle no face detected error
    if (errorMessage.toLowerCase().includes("face")) {
      return (
        <div className="bg-orange-50 border border-orange-200 rounded-lg p-6 text-center my-4">
          <div className="text-2xl mb-4">👤</div>
          <h3 className="text-orange-800 font-semibold text-lg mb-3">
            No Clear Face Detected
          </h3>
          <p className="text-gray-700 mb-4">
            {errorMessage}
          </p>
        </div>
      );
    }

    // Handle rate limit error
    if (errorMessage.toLowerCase().includes("limit")) {
      return (
        <div className="bg-orange-50 border border-orange-200 rounded-lg p-6 text-center my-4">
          <div className="text-2xl mb-4">⏳</div>
          <h3 className="text-orange-800 font-semibold text-lg mb-3">
            Free Analysis Limit Reached
          </h3>
          <p className="text-gray-700 mb-4">
            You've reached the limit for free photo analysis. Login to analyze unlimited photos, or try again in 5 hours! 🔒
          </p>
        </div>
      );
    }

    // Default error message
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-6 text-center my-4">
        <div className="text-2xl mb-4">⚠️</div>
        <h3 className="text-red-800 font-semibold text-lg mb-3">
          Oops! Something went wrong
        </h3>
        <p className="text-gray-700">
          {typeof errorMessage === 'string' ? errorMessage : 'Please try again later'}
        </p>
      </div>
    );

  } catch (e) {
    // If JSON parsing fails, render the error message directly
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-6 text-center my-4">
        <div className="text-2xl mb-4">⚠️</div>
        <h3 className="text-red-800 font-semibold text-lg mb-3">
          Error
        </h3>
        <p className="text-gray-700">{errorMessage}</p>
      </div>
    );
  }
};

const ProfileAnalyzer = () => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.user);
  const [converting, setConverting] = useState(false);
  const [previewUrls, setPreviewUrls] = useState([]);
  const fileInputRef = useRef(null);
  const [isProcessingComplete, setIsProcessingComplete] = useState(true);

  useEffect(() => {
    return () => {
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [previewUrls]);

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files);
      if (files.length + selectedFiles.length > 10) {
        alert("Maximum 10 photos allowed");
        return;
      }
      setAnalysis(null);
      setError(null);
      await processFiles(files);
    },
    [selectedFiles.length]
  );

  const handleAnalyze = async () => {
    try {
      setAnalyzing(true);
      setError(null);

      const formData = new FormData();
      selectedFiles.forEach((file, index) => {
        formData.append("photos", file, file.name || `photo-${index + 1}.jpg`);
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/profile-analyzer/analyze`,
        {
          method: "POST",
          headers: {},
          credentials: "include",
          body: formData,
        }
      );

      const data = await response.json();
      
      if (!response.ok) {
        if (data.message.includes("reached the limit for free photo analysis")) {
          setError(
            <div className="bg-orange-50 border border-orange-200 rounded-lg p-4 text-center">
              <p className="text-orange-800 font-medium mb-2">Free Analysis Limit Reached</p>
              <p className="text-gray-600">You've reached the limit for free photo analysis.</p>
              <p className="text-gray-600">Login to analyze unlimited photos, or try again in 5 hours! 🔒</p>
            </div>
          );
        } else {
          throw new Error(data.message || "Failed to analyze photos");
        }
        return;
      }

      if (data.success) {
        setAnalysis(data.data);
      } else {
        throw new Error(data.message || "Analysis failed");
      }
    } catch (err) {
      setError(err.message);
      console.error("Analysis error:", err);
    } finally {
      setAnalyzing(false);
    }
  };

  const processFiles = async (files) => {
    setConverting(true);
    setIsProcessingComplete(false);
    const processedFiles = [];
    const newPreviewUrls = [];

    try {
      for (const file of files) {
        const processedFile = await convertHeicToJpeg(file);
        
        // Create a new File object instead of modifying the existing one
        const newFile = new File(
          [processedFile], 
          file.name || `photo-${processedFiles.length + 1}.jpg`,
          { type: processedFile.type }
        );
        
        processedFiles.push(newFile);
        const previewUrl = URL.createObjectURL(newFile);
        newPreviewUrls.push(previewUrl);
      }

      // Update state once after processing all files
      setSelectedFiles(prev => [...prev, ...processedFiles]);
      setPreviewUrls(prev => [...prev, ...newPreviewUrls]);

    } catch (err) {
      setError("Failed to process some images. Please try again.");
      console.error(err);
    } finally {
      setConverting(false);
      setIsProcessingComplete(true);
    }
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedFiles.length > 10) {
      alert("Maximum 10 photos allowed");
      return;
    }
    setAnalysis(null);
    setError(null);
    await processFiles(files);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset input
    }
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    URL.revokeObjectURL(previewUrls[index]);
    setPreviewUrls(prev => prev.filter((_, i) => i !== index));
    if (selectedFiles.length === 1) {
      setAnalysis(null);
      setError(null);
    }
  };

  return (
    <>
      {!isAuthenticated && <Header />}
      <Container>
        <Title>AI Dating Profile Rater - Brutally Honest Feedback</Title>
        <Subtitle>
          Trained on 100,000+ successful dating profiles, our AI delivers
          brutally honest feedback that most humans won't tell you. We're here
          to boost your matches, even if the truth hurts. Ready for unfiltered
          analysis? Let's make your profile stand out! 🎯
          <div className="flex justify-center mt-4">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 max-w-3xl">
              <div className="bg-red-50 p-3 rounded-lg">
                <div className="text-red-600 font-semibold">1-5/10</div>
                <div className="text-sm text-gray-600">
                  Needs significant improvement. Hardly gets any matches.
                </div>
              </div>
              <div className="bg-yellow-50 p-3 rounded-lg">
                <div className="text-yellow-600 font-semibold">6-7/10</div>
                <div className="text-sm text-gray-600">
                  Gets few matches but could be better.
                </div>
              </div>
              <div className="bg-orange-50 p-3 rounded-lg">
                <div className="text-orange-600 font-semibold">8/10</div>
                <div className="text-sm text-gray-600">
                  Good profile. Consistent matches expected.
                </div>
              </div>

              {/* Centered flex container for 9/10 and 10/10 */}
              <div className="flex justify-center gap-3 col-span-2 md:col-span-3">
                <div className="bg-green-50 p-3 rounded-lg w-[calc(50%-0.375rem)] md:w-1/3">
                  <div className="text-green-600 font-semibold">9/10</div>
                  <div className="text-sm text-gray-600">
                    Excellent profile. High-quality matches likely.
                  </div>
                </div>
                <div className="bg-blue-50 p-3 rounded-lg w-[calc(50%-0.375rem)] md:w-1/3">
                  <div className="text-blue-600 font-semibold">10/10</div>
                  <div className="text-sm text-gray-600">
                    Perfect profile. Top-tier dating potential.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-2">
            <img src={EUImg} alt="" className="h-4" />
            <p className="font-normal text-xs ml-1">
              Made in the EU. We take your privacy seriously.
            </p>
          </div>
        </Subtitle>

        <UploadSection>
          <UploadBox onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
            <input
              ref={fileInputRef}
              type="file"
              id="file-upload"
              multiple
              accept="image/jpeg,image/jpg,image/png,image/webp,image/heic"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <CloudUploadIcon sx={{ fontSize: 48, color: "#ea580c", mb: 2 }} />
            <h2
              style={{
                fontSize: "24px",
                marginBottom: "16px",
                color: "#333",
                fontWeight: "500",
              }}
            >
              Get Brutally Honest Feedback
            </h2>
            <p
              style={{
                color: "#666",
                marginBottom: "24px",
              }}
            >
              Upload your photos and our AI will tell you exactly why you're not
              getting matches
            </p>
            <label htmlFor="file-upload">
              <Button as="span">SELECT PHOTOS</Button>
            </label>
          </UploadBox>

          <PreviewSection>
            <h3 style={{ fontSize: "20px", marginBottom: "16px", color: "#333" }}>
              Selected Photos ({selectedFiles.length}/10)
            </h3>
            {converting && (
              <div className="text-orange-600 text-sm mb-4">
                Reading images, please wait...
              </div>
            )}
            <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
              gap: "16px",
            }}>
              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    aspectRatio: "1",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={previewUrls[index]}
                    alt={`Preview ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <button
                    onClick={() => handleRemoveFile(index)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      backgroundColor: "rgba(255,255,255,0.8)",
                      border: "none",
                      borderRadius: "50%",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 20 }} />
                  </button>
                </div>
              ))}
            </div>
          </PreviewSection>
        </UploadSection>

        <div
          style={{
            textAlign: "center",
            marginTop: "48px",
          }}
        >
          <Button
            disabled={selectedFiles.length === 0 || analyzing || converting || !isProcessingComplete}
            onClick={handleAnalyze}
          >
            {analyzing ? "ANALYZING YOUR PHOTOS..." : 
             converting ? "PROCESSING IMAGES..." : 
             "GET FREE PHOTO ANALYSIS"}
          </Button>
        </div>

        {analysis && formatAnalysisResults(analysis, navigate)}

        {error && renderError(error)}
      </Container>
      {!isAuthenticated && <Footer />}
    </>
  );
};

export default ProfileAnalyzer;
