import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import modelReducer from "./slices/model";
import miscReducer from "./slices/mics";
import historyReducer from "./slices/history";

const store = configureStore({
  reducer: {
    user: userReducer,
    model: modelReducer,
    misc: miscReducer,
    history: historyReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
